﻿import { get, initForm } from "@/Utils";
import { initIdAndMobileLogin } from "@/sharedComponents/Header/Login";
import { StatusCodes } from "http-status-codes";
import { initToggleButtons } from "@/RegistrationModule";

function executeRegisterAccount(response: Response) {
  if (response.status === StatusCodes.OK) {
    window.location.replace(window.config.homeUrl);
  }
  return response.text().then(html => {
    get("registration").innerHTML = html;
    initView();
  });
};

function formPosted(response: Response) {
  if (response.url.endsWith("RegisterAccount")) {
    return executeRegisterAccount(response);
  }
  return () => { };
}


function initView() {
  initToggleButtons();
  initIdAndMobileLogin(true)
  initIdAndMobileLogin(false)
  initForm("regForm", formPosted);
}

window.addEventListener("load", () => {
  initView();
});